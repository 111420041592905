import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    return () => (
      <div class="tabbar">
        <van-tabbar route fixed placeholder border>
          <van-tabbar-item replace to="/quotes" icon="wap-home">
            行情
          </van-tabbar-item>
          <van-tabbar-item replace to="/trade" icon="bars">
            交易
          </van-tabbar-item>
          <van-tabbar-item replace to="/new" icon="manager">
            资讯
          </van-tabbar-item>
          <van-tabbar-item replace to="/my" icon="manager">
            我的
          </van-tabbar-item>
        </van-tabbar>
      </div>
    )
  }
})
