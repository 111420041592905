import { createApp, provide, h } from 'vue'
import { ApolloClients } from '@vue/apollo-composable'
import apolloClient from '@/apollo'
import dmzClient from '@/apollo/dmz'
import App from './App.vue'
import router from './router'
import { Empty, Skeleton } from 'vant'
import VConsole from 'vconsole'

import './icons/index'
import './styles/index.scss'

import './utils/auth'
import './router/permission'
import 'swiper/css'

if (process.env.VUE_APP_VCONSOLE === 'show') {
  const vConsole = new VConsole()
  console.log(vConsole)
}

const app = createApp({
  setup() {
    provide(ApolloClients, {
      default: apolloClient,
      dmz: dmzClient
    })
  },
  render: () => h(App)
})
app.use(router).use(Empty).use(Skeleton).mount('#app')
