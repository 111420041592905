import { ApolloClient, ApolloLink, InMemoryCache, HttpLink, from } from '@apollo/client/core'
import { onError } from '@apollo/client/link/error'
import signWrap from './sign'
import { Toast } from 'vant'

const httpLink = new HttpLink({
  uri: process.env.VUE_APP_BASE_API
})

const additiveLink = new ApolloLink((operation, forward) => {
  operation.setContext(() => ({
    headers: {
      'content-type': 'application/json',
      accept: 'application/json',
      ...signWrap(operation)
    }
  }))

  return forward(operation).map((data) => {
    console.log(data.data)
    return data
  })
})

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error: any) => {
      console.log('message', error)
      // Toast.fail(error.desc)
      const { err } = error
      switch (err) {
        case 545:
        case 543:
          Toast.fail('Token失效，请重新登录！')
          break
        default:
          Toast.fail('请求异常')
          break
      }
    })
  }

  if (networkError) console.log(`[Network error]: ${networkError}`)
  return forward(operation)
})

// If you provide a link chain to ApolloClient, you
// don't provide the `uri` option.
const apolloClient = new ApolloClient({
  // The `from` function combines an array of individual links
  // into a link chain
  link: from([additiveLink, errorLink, httpLink]),
  cache: new InMemoryCache()
})

export default apolloClient
