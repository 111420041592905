import { getURLParameters } from '@/utils/utils'
import { base64Decrypt } from '@/utils/crypto'

export default function useUrlParams () {
  const { params } = getURLParameters()
  try {
    if (params) {
      const query = base64Decrypt(params)
      const { appId, appSecret, newsId, newsTime } = JSON.parse(query)
      const obj: any = {
        appId: appId || process.env.VUE_APP_H5_APPID,
        appSecret: appSecret || process.env.VUE_APP_H5_APPSECRET,
        newsId: newsId || 251881,
        newsTime: newsTime || '2021-10-29 15:27:00.291'
      }
      return obj
    } else {
      const obj: any = {
        appId: process.env.VUE_APP_H5_APPID,
        appSecret: process.env.VUE_APP_H5_APPSECRET,
        newsId: 9171,
        newsTime: '2022-01-13 13:47:00.666'
      }
      return obj
    }
  } catch (error) {
    alert('JSON解析错误')
  }
}
