import UserAgent from '@/utils/useragent'
import * as storage from '@/utils/storage'

interface StockInfo {
  name: string
  code: string
  market: string
}

// 判断是否在APP内
export const isInAppWebview = () => {
  try {
    window.jockey.invoke('isInAppWebview', {}, () => {
      console.log(1)
    })
    return true
  } catch (error) {
    return false
  }
}

/**
 * 获取用户信息
 * @returns
 */
export const getUserInfo = () => {
  return new Promise((resolve) => {
    if (isInAppWebview()) {
      window.jockey.invoke('getUserInfo', {}, (result: any) => {
        storage.set('userInfo', result.data)
        return resolve(result.data)
      })
    } else {
      const data = {
        sessionId: 'dcd6e44b515241e9a5682e25b00a4c1a',
        deviceId: 'k9mxt7vdMbwVOz5Z',
        userInfo: {
          phone: '13700000014'
        }
      }
      storage.set('userInfo', data)
      resolve(data)
    }
  })
}

/**
 * 跳转股票详情页面
 * name 股票名称
 * code 股票代码
 * market 股票市场
 */
export const viewStockInfoAction = (data: StockInfo) => {
  return new Promise((resolve) => {
    if (isInAppWebview()) {
      window.jockey.invoke('viewStockInfoAction', data, (result: any) => {
        return resolve(result.data)
      })
    } else {
      console.log('移动端不支持', data)
      resolve(false)
    }
  })
}
