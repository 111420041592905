import { BigNumber } from 'bignumber.js'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

type BigNumberInputTypes = number | string | BigNumber
type EmptyTypes = null | undefined

// 数据小数点最大精度位数
export const DecimalPointNumber = 8

// 数据格式
export const bigNumberFmt = {
  prefix: '',
  decimalSeparator: '.',
  groupSeparator: ',',
  groupSize: 3,
  secondaryGroupSize: 3
}

/*
 * 格式化服务器数据
 * @params obj: 数字格式化
 * @params display: 是否显示为服务端模式
 *                  false 服务端模式 true 正常带逗号格式化
 * */
export function beautyFormatRemoteString(obj: BigNumberInputTypes | EmptyTypes, display = true): string {
  if (obj === null || obj === undefined) {
    return ''
  }
  return getHighPrecisionValue(obj, display)
}

/*
 * 返回数据相乘后结果
 * */
export function bigNumberMultiplied(a: BigNumberInputTypes, b: BigNumberInputTypes): string {
  if (a && b) {
    return beautyFormatRemoteString(new BigNumber(a).multipliedBy(b))
  }
  return ''
}

/*
 * 两个大数大小排序
 * */
export function bigNumberSort(a: BigNumberInputTypes, b: BigNumberInputTypes): number {
  return new BigNumber(a).minus(b).toNumber()
}

function calculateDecimalPlaces(str: string) {
  const weedOutZeros = str.replace(/(0*)$/g, '')
  let decimalPlaces = 0
  if (weedOutZeros.includes('.')) {
    decimalPlaces = weedOutZeros.length - weedOutZeros.indexOf('.') - 1
  }
  return decimalPlaces
}

/*
 * 返回: 返回高精度值的字符串
 * @param number: 结算单价
 * */
export function getHighPrecisionValue(number: BigNumberInputTypes, display = false): string {
  if (number === '') {
    return ''
  }
  const numberString = number.toString().replace(/,/g, '')
  // const acc = numberString.indexOf('.')
  let fixedNumberString = ''
  if (display) {
    const decimalPlaces = calculateDecimalPlaces(numberString)
    fixedNumberString = new BigNumber(numberString)
      .decimalPlaces(DecimalPointNumber)
      .toFormat(decimalPlaces, BigNumber.ROUND_HALF_UP, bigNumberFmt)
  } else {
    fixedNumberString = new BigNumber(numberString).toFixed()
  }
  return fixedNumberString
}

/*
 * 返回: 易于阅读的时间格式
 * @param date: 时间值
 * */
export function dateFormatPretty(date: string) {
  dayjs.extend(utc)
  let format = ''
  const dataObj = dayjs(date)
  if (dataObj.isValid()) {
    const nowObj = dayjs()

    const value = nowObj.diff(dataObj)
    // TODO: 0-59秒未考虑
    const MINITE_59 = 1000 * 59 * 60
    if (value < MINITE_59) {
      // 多少分钟前优先级最高
      format = `${nowObj.diff(dataObj, 'minute')}分钟前`
    } else {
      const dateTime = dayjs(dayjs(date).format('YYYY-MM-DD'))
      const nowTime = dayjs(dayjs().format('YYYY-MM-DD'))
      if (nowTime.diff(dateTime, 'day') === 0) {
        // 当天
        format = `今天${dataObj.format('HH:mm')}`
      } else if (nowTime.diff(dateTime, 'year') === 0) {
        // 今年
        format = `${dataObj.format('MM-DD HH:mm')}`
      } else if (nowTime.diff(dateTime, 'year') !== 0) {
        // 去年及以前
        format = `${dataObj.format('YYYY-MM-DD HH:mm')}`
      }
    }
  }
  return format
}

// 获取地址栏参数
export const getURLParameters = (url = window.location.href) => {
  const params = url.match(/([^?=&]+)(=([^(&|#|/)]*))/g)
  if (params === null) {
    return {}
  } else {
    return params.reduce((a: any, v) => {
      a[v.slice(0, v.indexOf('='))] = v.slice(v.indexOf('=') + 1)
      return a
    }, {})
  }
}
